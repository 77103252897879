/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_mobile_analytics_app_id": "400a6387dbb0407db1f18e3dbf3a1b5d",
    "aws_mobile_analytics_app_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://ebkgzzatq5.execute-api.ap-southeast-2.amazonaws.com/test",
            "region": "ap-southeast-2"
        },
        {
            "name": "CalendlyApiV1",
            "endpoint": "https://nczpy6outj.execute-api.ap-southeast-2.amazonaws.com/test",
            "region": "ap-southeast-2"
        },
        {
            "name": "CalendlyWebooksV1",
            "endpoint": "https://e62wtzfbh6.execute-api.ap-southeast-2.amazonaws.com/test",
            "region": "ap-southeast-2"
        },
        {
            "name": "SendEmail",
            "endpoint": "https://dxw9aij3bl.execute-api.ap-southeast-2.amazonaws.com/test",
            "region": "ap-southeast-2"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://lezuh27cifaw5llgepf6was76a.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cognito_identity_pool_id": "ap-southeast-2:d451511e-49bc-44f3-b69d-8914fc786b28",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_LG0b4SNai",
    "aws_user_pools_web_client_id": "53l2icpgavo1rcsssek7dd26k0",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "tbnweb61650-test",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
